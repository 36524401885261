import { Grid, Typography } from '@mui/material';
import { InfoCard } from '../InfoCard';

export type FeedbackMessage = {
  age?: number;
  gender?: string;
  message: string;
};

export type ICsatReport = {
  total: number;
  capyEndorsements: number;
  serviceEndorsement: number;
  feedback?: FeedbackMessage[];
};

export const CsatReport = (props: { data: ICsatReport }) => {
  const { data } = props;
  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Visitors satisfaction
      </Typography>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6} lg={3} xl={3}>
          <InfoCard
            label={'Capy experience'}
            value={data.capyEndorsements + '%'}
            accent={true}
            contextLabel={`of users finds Capy service helpful`}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3} xl={3}>
          <InfoCard
            label={'Overall Service'}
            value={data.serviceEndorsement + '%'}
            contextLabel={`of users finds overall service satisfactory`}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3} xl={3}>
          <InfoCard
            label={'Users feedback'}
            value={data.feedback?.length || 0}
            contextLabel={`users left a feedback, click to find out more`}
            onClick={() => console.log('callback invoked')}
          />
        </Grid>
      </Grid>
    </div>
  );
};
