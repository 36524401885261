import { IAuth } from '../features/auth.slice';
import { apiSlice } from './api.service';

interface Credentials {
  email: string;
}

interface OTP extends Credentials {
  otp: string;
}

interface AuthResponse {
  data: IAuth;
}

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // <return type, first argument type to the payload>
    login: build.mutation<any, Credentials>({
      query: (data) => ({
        url: `/auth/sign-in`,
        method: 'POST',
        body: data,
      }),
    }),

    verifyOTP: build.mutation<AuthResponse, OTP>({
      query: (data) => ({
        url: `/auth/sign-in/verify`,
        method: 'POST',
        body: data,
      }),
    }),

    logout: build.mutation<any, void>({
      query: () => ({
        url: `/auth/sign-out`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useVerifyOTPMutation, useLogoutMutation } = authApiSlice;
