import { Grid, Typography } from '@mui/material';
import { InfoCard } from '../InfoCard';
import { NexusDialog } from '../NexusDialog';
import { NexusDataGrid } from '../NexusDataGrid';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';

export type IMentalState = {
  beforeMood: string;
  afterMood: string;
  moodImprovement: string;
};
export const MentalStateReport = (props: { data: IMentalState }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<any>('');
  const rows: GridRowsProp = [
    { id: 1, firstName: 'Lorenzo', lastName: 'Donati', email: 'lodonati@gmail.com' },
    { id: 2, firstName: 'Michela', lastName: 'Perdoni', email: 'michela.perdoni2@kpmg.com' },
    { id: 3, firstName: 'Hans', lastName: 'Schmidt', email: 'hans@schmidt.com' },
  ];

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'lastName', headerName: 'Last name', width: 150 },
    { field: 'email', headerName: 'Phone number', width: 250 },
  ];

  const dialogContentInjector = () => {
    return <NexusDataGrid rows={rows} cols={columns} exportFileName='requested-users' />;
  };

  const handleClickOpen = () => {
    if (!dialogTitle) {
      setDialogTitle('Users requested help');
    }
    setDialogContent(dialogContentInjector());

    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <div>
        <Typography variant='h4' gutterBottom>
          Mental state
        </Typography>

        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <InfoCard
              label={'Before Capy'}
              value={'Sad'}
              contextLabel={`was the most prevalent feeling recorded`}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} xl={3}>
            <InfoCard
              label={'After Capy'}
              value={'Content'}
              contextLabel={`was the most prevalent feeling recorded`}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} xl={3}>
            <InfoCard
              label={'Mood Improvement'}
              value={'67%'}
              accent={true}
              contextLabel={`users improved their mood after using Capy`}
              onClick={() => handleClickOpen()}
            />
          </Grid>
        </Grid>
        <NexusDialog
          title={dialogTitle}
          content={dialogContent}
          dialogOpened={openDialog}
          closeDialogFn={handleDialogClose}
        />
      </div>
    </>
  );
};
