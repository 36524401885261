import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useEffect, useRef } from 'react';

export type NexusDialogProps = {
  title: string;
  content: any;
  dialogOpened: boolean;
  closeDialogFn(): void;
};

export const NexusDialog = (props: NexusDialogProps) => {
  // const [open, setOpen] = useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.dialogOpened) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.dialogOpened]);

  return (
    <Dialog
      open={props.dialogOpened}
      onClose={props.closeDialogFn}
      scroll={'paper'}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>{props.title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDialogFn}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
