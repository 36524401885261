import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

type Props = {
  label: string;
  value: number | string;
  accent?: boolean;
  contextLabel?: string;
  onClick?(): any;
};

export const InfoCard = (props: Props) => {
  return (
    <Box sx={{ minWidth: 250, height: '100%' }}>
      <Card sx={{ height: '100%', bgcolor: props.accent ? 'primary.dark' : '' }}>
        {props.onClick ? (
          <CardActionArea onClick={props.onClick} sx={{ height: '100%' }}>
            <CardContentBody {...props} />
          </CardActionArea>
        ) : (
          <CardContentBody {...props} />
        )}
      </Card>
    </Box>
  );
};

const CardContentBody = (props: Props) => {
  return (
    <CardContent>
      <Typography
        gutterBottom
        variant='overline'
        color={props.accent ? `primary.contrastText` : `text.primary`}
      >
        {props.label}
      </Typography>
      <Typography
        variant='h1'
        color={props.accent ? `primary.contrastText` : `text.primary`}
        textAlign={'center'}
      >
        {props.value}
      </Typography>
      {props.contextLabel && (
        <Typography
          variant='body2'
          color={props.accent ? `primary.contrastText` : `text.primary`}
          textAlign={'center'}
        >
          {props.contextLabel}
        </Typography>
      )}
    </CardContent>
  );
};
