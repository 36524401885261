// import './home.styles.scss';
import { ILocation, useGetLocationsQuery } from '../../state/services/locations.service';

export const Locations = () => {
  const { data } = useGetLocationsQuery();
  return (
    <>
      <h1>Today's activity report</h1>
      {data?.map((device: ILocation) => (
        <p key={device.id}>
          {device.name} ({device.friendlyId})
        </p>
      ))}
    </>
  );
};
