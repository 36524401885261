import { BarChartCard } from '../BarChartCard';

export type GenderProps = { gender: string; total: number }[];

export const GenderReport = (props: { data: GenderProps }) => {
  const { data } = props;
  const series = [
    {
      dataKey: 'total',
    },
  ];
  const yAxis = [
    {
      scaleType: 'band',
      dataKey: 'gender',
      data: ['Female', 'Male', 'Other'],
      colorMap: {
        type: 'ordinal',
        colors: ['#B800D8', '#2E96FF', '#02B2AF'],
      },
    },
  ];

  const xAxis = [
    {
      // label: 'interacted with the Capy application',
    },
  ];

  return (
    <BarChartCard
      label='Gender distribution'
      dataset={data}
      yAxis={yAxis}
      series={series}
      xAxis={xAxis}
      height={300}
      layout='horizontal'
    />
  );
};
