import { Outlet, createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { ErrorPage } from './error/error.route';
import { Login } from './auth/login.route';
import { ProtectedRoute } from '../components/protected/protected-route.component';
import { Home } from './home/home.route';
import { Locations } from './locations/locations.route';

export const appRouter = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: '',
        element: <ProtectedRoute />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'reports',
            element: (
              <div>
                reports
                <Outlet />
              </div>
            ),
            children: [
              {
                path: ':id',
                element: <div>report for date</div>,
              },
            ],
          },
          {
            path: 'control-center',
            element: (
              <div>
                Control center
                <Outlet />
              </div>
            ),
            children: [
              {
                path: 'accounts',
                element: <div>accounts</div>,
              },
              {
                path: 'users',
                element: <div>users</div>,
              },
              {
                path: 'locations',
                element: <Locations />,
              },
              {
                path: 'placements',
                element: <div>locations</div>,
              },
              {
                path: 'devices',
                element: <div>locations</div>,
              },
            ],
          },
        ],
      },
    ],
  },
]);
