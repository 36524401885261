import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it-ch';

import { Provider } from 'react-redux';
import { store } from './state/store';
import { appRouter } from './routes/router';

const rootDOM = document.getElementById('root');

if (rootDOM) {
  const root = ReactDOM.createRoot(rootDOM);

  root.render(
    <StrictMode>
      <Provider store={store}>
        {/*  adapterLocale='it-ch' */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <App /> */}
          <RouterProvider router={appRouter} />
        </LocalizationProvider>
      </Provider>
    </StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
