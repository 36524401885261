import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from './components/header/Header';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import React from 'react';

// When using TypeScript 4.x and above
import type {} from '@mui/x-data-grid/themeAugmentation';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
        components: {
          // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
          MuiDataGrid: {
            styleOverrides: {
              root: {
                // backgroundColor: 'red',
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className='app-wrapper'>
        <Header></Header>
        <header className='app-header'></header>
        <ToastContainer
          position='top-center'
          autoClose={5000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
        />
        <div className='page-body'>
          <Outlet />
        </div>
        <footer className='app-footer'>This is Footer</footer>
      </div>
    </ThemeProvider>
  );
};

export default App;
