import { Typography, Grid } from '@mui/material';
import { useState } from 'react';
import { NexusDialog } from '../NexusDialog';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { NexusDataGrid } from '../NexusDataGrid';
import { InfoCard } from '../InfoCard';

export type IEngagementReport = {
  total: number;
  conversion: number;
};

export const EngagementReport = (props: { data: IEngagementReport }) => {
  const { total, conversion } = props.data;
  // const contextPercentage = ((14 / 100) * 100).toPrecision(4);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<any>('');

  const rows: GridRowsProp = [
    { id: 1, firstName: 'Lorenzo', lastName: 'Donati', email: 'lodonati@gmail.com' },
    { id: 2, firstName: 'Michela', lastName: 'Perdoni', email: 'michela.perdoni2@kpmg.com' },
    { id: 3, firstName: 'Hans', lastName: 'Schmidt', email: 'hans@schmidt.com' },
  ];

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'lastName', headerName: 'Last name', width: 150 },
    { field: 'email', headerName: 'Phone number', width: 250 },
  ];

  const dialogContentInjector = () => {
    return <NexusDataGrid rows={rows} cols={columns} exportFileName='requested-users' />;
  };

  const handleClickOpen = () => {
    if (!dialogTitle) {
      setDialogTitle('Users requested help');
    }
    setDialogContent(dialogContentInjector());

    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <div>
        <Typography variant='h4' gutterBottom>
          Engagement
        </Typography>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <InfoCard
              label={'Total activity'}
              value={total}
              contextLabel={`users engaged with the Capy service`}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} xl={3}>
            <InfoCard
              label={'Users requested offer'}
              value={conversion}
              accent={true}
              contextLabel={`potential of 8,400 CHF of revenue growth`}
              onClick={() => handleClickOpen()}
            />
          </Grid>
        </Grid>
      </div>

      <NexusDialog
        title={dialogTitle}
        content={dialogContent}
        dialogOpened={openDialog}
        closeDialogFn={handleDialogClose}
      />
    </>
  );
};
