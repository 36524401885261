import { AppBar, Box, Button, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NexusAvatar } from '../avatar/NexusAvatar';
import logo from '../../assets/NexusSign.svg';
import { useAppSelector } from '../../state/hooks';
export const Header = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  return (
    <AppBar position='static' sx={{ marginBottom: '3rem' }}>
      <Toolbar>
        {/* logo */}
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <img src={logo} alt='' height={'20px'} />
        </Box>
        {/* <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
        </Typography> */}
        {isAuthenticated && (
          <Stack direction='row' spacing={2}>
            <Button color='inherit'>Reports</Button>

            <Button
              color='inherit'
              id='profile-button'
              onClick={handleClick}
              aria-controls={open ? 'profile' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              startIcon={<NexusAvatar />}
            >
              <Stack>
                <Typography variant='caption' textAlign={'left'}>
                  Alen
                </Typography>
                <Typography variant='caption' textAlign={'left'}>
                  Novakovic
                </Typography>
              </Stack>
            </Button>
          </Stack>
        )}
        <Menu
          id='profile'
          anchorEl={anchor}
          open={open}
          MenuListProps={{
            'aria-labelledby': 'profile-button',
          }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem>My profile</MenuItem>
          <MenuItem>Change password</MenuItem>
          <MenuItem>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
