import { BarChartCard } from '../BarChartCard';

export type AgeProps = {
  // 0 = 0 - 17
  // 1 = 18 - 24
  // 2 = 25 - 34
  // 3 = 35 - 44
  // 4 = 44 - 54
  // 5 = 55 - 64
  // 6 = 65 - 74
  // 7 = 75+
  female: number[];
  male: number[];
  other: number[];
};

export const AgeReport = (props: { data: AgeProps }) => {
  const { data } = props;
  const xLabels = [
    '0 - 17',
    '18 - 24',
    '25 - 34',
    '35 - 44',
    '44 - 54',
    '55 - 64',
    '65 - 74',
    '75+',
  ];
  // https://mui.com/x/react-charts/styling/
  const series = [
    { data: data.female, label: 'Female', id: 'femaleId', stack: 'total', color: '#B800D8' },
    { data: data.male, label: 'Male', id: 'maleId', stack: 'total', color: '#2E96FF' },
    { data: data.other, label: 'Other', id: 'otherId', stack: 'total', color: '#02B2AF' },
  ];

  const xAxis = [{ data: xLabels, scaleType: 'band' }];
  return (
    <BarChartCard
      label='Age distribution'
      series={series}
      xAxis={xAxis}
      height={300}
      layout='vertical'
    />
  );
};
