import { DataGrid, GridToolbar, GridRowsProp, GridColDef } from '@mui/x-data-grid';

export type NexusDataGridProps = {
  exportFileName?: string;
  rows: GridRowsProp;
  cols: GridColDef[];
};

export const NexusDataGrid = (props: NexusDataGridProps) => {
  return (
    <div>
      <DataGrid
        rows={props.rows}
        columns={props.cols}
        sx={{
          '@media print': {
            '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
          },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              fileName: `${new Date().toISOString()}-Nexus-${
                props.exportFileName || 'data-grid-export'
              }`,
              delimiter: ';',
              utf8WithBom: true,
            },
            printOptions: {
              pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
              // https://github.com/mui/mui-x/pull/13034 when this merged uncomment the following line
              // hideFooter: true,
              hideToolbar: true,
            },
          },
        }}
      />
    </div>
  );
};
