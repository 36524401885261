import { Grid, Typography } from '@mui/material';
import { GenderProps, GenderReport } from './GenderReport';
import { AgeProps, AgeReport } from './AgeReport';
import { OriginProps, OriginReport } from './OriginReport';

export type IDemographyReport = {
  gender: GenderProps;
  age: AgeProps;
  origin: OriginProps;
};

export const DemographyReport = (props: { data: IDemographyReport }) => {
  const { data } = props;
  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Demography
      </Typography>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <GenderReport data={data.gender} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <OriginReport data={data.origin} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <AgeReport data={data.age} />
        </Grid>
      </Grid>
    </div>
  );
};
