import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { RootState } from '../store';
import { clearAuthState, setAccessToken } from '../features/auth.slice';

type RefreshTokenResponse = {
  data: {
    data: {
      accessToken: string;
    };
  };
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = (api.getState() as RootState).auth.refreshToken;
    const refreshResult = (await baseQuery(
      { url: `${BASE_URL}/auth/token/refresh`, body: { refreshToken }, method: 'POST' },
      api,
      extraOptions
    )) as RefreshTokenResponse;
    if (!refreshResult.data) {
      api.dispatch(clearAuthState());
    } else {
      console.info(refreshResult.data);
      api.dispatch(setAccessToken(refreshResult.data.data.accessToken));
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Auth', 'User', 'Location'],
  endpoints: () => ({}),
});
