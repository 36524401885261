import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IUser {
  email: string;
  friendlyId: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
}

export interface IAuth {
  user: IUser | null;
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
}

const userItem = localStorage.getItem('user');
const accessTokenItem = localStorage.getItem('accessToken');
const refreshTokenItem = localStorage.getItem('refreshToken');
const isAuthenticatedItem = localStorage.getItem('isAuthenticated');

const initialState: IAuth = {
  user: userItem ? JSON.parse(userItem) : null,
  accessToken: accessTokenItem || null,
  refreshToken: refreshTokenItem || null,
  isAuthenticated: isAuthenticatedItem === 'true' ? true : false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState, // slice type inferred from initialState
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
      localStorage.setItem('refreshToken', action.payload);
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      localStorage.setItem('isAuthenticated', `${action.payload}`);
    },
    clearAuthState: (state) => {
      state = initialState;
      localStorage.clear();
    },
  },
});

export const { setUser, setAccessToken, setRefreshToken, setIsAuthenticated, clearAuthState } =
  authSlice.actions;
export default authSlice.reducer;
