import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id='error-page'>
      <h1>Oops!</h1>
      <p>An error has occured 👀</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
