import './home.styles.scss';
import { IDevice, useGetDevicesQuery } from '../../state/services/devices.service';
import { Box, Stack, Typography } from '@mui/material';
import {
  DemographyReport,
  IDemographyReport,
} from '../../components/reports/demography/DemographyReport';
import {
  EngagementReport,
  IEngagementReport,
} from '../../components/reports/engagement/EngagementReport';
import { CsatReport, ICsatReport } from '../../components/reports/csat/CsatReport';
import {
  IMentalState,
  MentalStateReport,
} from '../../components/reports/mental-state/MentalStateReport';

export type IReport = {
  engagement: IEngagementReport;
  demography: IDemographyReport;
  csat: ICsatReport;
  mentalState: IMentalState;
};

export const Home = () => {
  // 1. get the data from the server
  // 2. pass on parts of data to corresponding reports
  const { engagement, demography, csat, mentalState } = {
    engagement: {
      total: 100,
      conversion: 14,
      offerValue: 8400,
    },
    demography: {
      gender: [
        { gender: 'female', total: 46 },
        { gender: 'male', total: 31 },
        { gender: 'other', total: 23 },
      ],
      // gender: {
      //   female: 46,
      //   male: 31,
      //   other: 23,
      //   total: 100,
      // },
      age: {
        // 0 = 0 - 17
        // 1 = 18 - 24
        // 2 = 25 - 34
        // 3 = 35 - 44
        // 4 = 44 - 54
        // 5 = 55 - 64
        // 6 = 65 - 74
        // 7 = 75+
        female: [0, 0, 6, 16, 18, 0, 1, 9],
        male: [0, 0, 3, 6, 18, 1, 3, 0],
        other: [0, 0, 2, 16, 5, 0, 0, 0],
      },
      origin: {
        local: 89,
        international: 11,
      },
    },
    csat: {
      total: 100,
      capyEndorsements: 64,
      serviceEndorsement: 70,
      feedback: [
        { age: 36, gender: 'male', message: 'Improve this' },
        { age: 41, gender: 'unspecified', message: 'Improve that' },
      ],
    },
    mentalState: {
      beforeMood: 'Sad',
      afterMood: 'Content',
      moodImprovement: '67%',
    },
  };

  const { data, isLoading, isError } = useGetDevicesQuery();

  return (
    <>
      {data?.map((device: IDevice) => (
        <p key={device.id}>
          {device.name} ({device.IMEI})
        </p>
      ))}
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={10}>
          <Typography variant='h2'>Today's overview</Typography>
          <EngagementReport data={engagement} />
          <MentalStateReport data={mentalState} />
          <CsatReport data={csat} />
          <DemographyReport data={demography} />
        </Stack>
      </Box>
    </>
  );
};
