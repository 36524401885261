import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';

type Props = {
  label: string;
  series: any[];
  dataset?: any;
  xAxis?: any[];
  yAxis?: any[];
  width?: number;
  height?: number;
  layout?: 'horizontal' | 'vertical';
  contextLabel?: string;
  onClick?(): any;
};

export const BarChartCard = (props: Props) => {
  return (
    <Box sx={{ minWidth: 250, height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        {props.onClick ? (
          <CardActionArea onClick={props.onClick}>
            <CardContentBody {...props} />
          </CardActionArea>
        ) : (
          <CardContentBody {...props} />
        )}
      </Card>
    </Box>
  );
};

const CardContentBody = (props: Props) => {
  const chartProps = { ...props };
  delete chartProps.contextLabel;
  delete chartProps.onClick;
  return (
    <CardContent>
      <Typography gutterBottom variant='overline' color='text.secondary'>
        {props.label}
      </Typography>
      <BarChart
        borderRadius={4}
        grid={chartProps.layout === 'vertical' ? { horizontal: true } : { vertical: true }}
        {...chartProps}
      />
      {props.contextLabel && (
        <Typography variant='body2' color='text.secondary'>
          {props.contextLabel}
        </Typography>
      )}
    </CardContent>
  );
};
