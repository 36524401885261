import { apiSlice } from './api.service';

export interface IDevice {
  name: string;
  IMEI: string;
  friendlyId: string;
  status: string;
  created_at: string;
  updated_at: string;
  id: string;
}

interface IDevicesResposne {
  data: IDevice[];
}
const locationApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // <return type, first argument type to the payload>
    getDevices: build.query<IDevice[], void>({
      query: (data) => ({
        url: `/devices`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDevicesQuery } = locationApiSlice;
