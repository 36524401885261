import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { useLoginMutation, useVerifyOTPMutation } from '../../state/services/auth.service';
import { useNavigate } from 'react-router-dom';
import {
  IUser,
  setAccessToken,
  setIsAuthenticated,
  setRefreshToken,
  setUser,
} from '../../state/features/auth.slice';
import { Stack, Typography } from '@mui/material';

export const Login = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [login, loginStatus] = useLoginMutation();
  const [verifyOTP, verificationStatus] = useVerifyOTPMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {}, []);
  if (isAuthenticated) {
    navigate('/');
  }

  const validateEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormValues({ email: e.target.value, password: '' });
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidEmail = regex.test(formValues.email);
    setCanSubmit(isValidEmail);
  };

  const validatePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormValues({ ...formValues, password: e.target.value });
    setCanSubmit(formValues.password ? true : false);
  };

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await login({ email: formValues.email }).unwrap();
      setIsOTPSent(true);
      setCanSubmit(false);
    } catch (error) {
      toast.error('Unrecognized user');
    }
  };

  const handlePassword = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const response = await verifyOTP({
        email: formValues.email,
        otp: formValues.password,
      }).unwrap();

      dispatch(setUser(response.data.user as IUser));
      dispatch(setRefreshToken(response.data.refreshToken as string));
      dispatch(setAccessToken(response.data.accessToken as string));
      dispatch(setIsAuthenticated(!!response.data.isAuthenticated));
      navigate('/');
      toast.success(`Welcome to the Nexus`);
    } catch (error) {
      toast.error('Incorrect credentials provided');
    }
  };

  // 4. render page
  return (
    <>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        display={'flex'}
        flexDirection={'column'}
        sx={{ maxWidth: '350px', margin: 'auto' }}
        onSubmit={isOTPSent ? handlePassword : handleSignIn}
      >
        <Typography variant='h2' gutterBottom>
          Sign in
        </Typography>
        <Stack spacing={2}>
          <TextField
            id='outlined-basic'
            label='Email'
            variant='outlined'
            size='small'
            fullWidth
            required
            margin='normal'
            placeholder='Enter your email'
            value={formValues.email}
            onInput={validateEmailInput}
            disabled={loginStatus.isLoading || isOTPSent}
          />
          {isOTPSent && (
            <TextField
              id='outlined-basic'
              label='Password'
              variant='outlined'
              size='small'
              fullWidth
              margin='normal'
              placeholder='Enter your password'
              value={formValues.password}
              onChange={validatePasswordInput}
              disabled={verificationStatus.isLoading}
            />
          )}
          <Button
            type='submit'
            variant='contained'
            disabled={!canSubmit || loginStatus.isLoading || verificationStatus.isLoading}
          >
            Sign in
          </Button>
        </Stack>
      </Box>
    </>
  );
};
