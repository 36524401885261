import { apiSlice } from './api.service';

export interface ILocation {
  name: string;
  description: string;
  friendlyId: string;
  status: string;
  placements: string[];
  created_at: Date;
  updated_at: Date;
  id: string;
}

const locationApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // <return type, first argument type to the payload>
    getLocations: build.query<ILocation[], void>({
      query: (data) => ({
        url: `/locations`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetLocationsQuery } = locationApiSlice;
