import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';

type Props = {
  label?: string;
  data?: any;
  width?: number;
  height?: number;
  onClick?(): any;
};

export const PieChartCard = (props: Props) => {
  return (
    <Box sx={{ minWidth: 250, height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        {props.onClick ? (
          <CardActionArea onClick={props.onClick}>
            <CardContentBody {...props} />
          </CardActionArea>
        ) : (
          <CardContentBody {...props} />
        )}
      </Card>
    </Box>
  );
};

const CardContentBody = (props: Props) => {
  const { label, data, height } = props;
  return (
    <CardContent>
      <Typography gutterBottom variant='overline' color='text.secondary'>
        {label}
      </Typography>
      <PieChart
        series={[
          {
            startAngle: -90,
            endAngle: 90,
            paddingAngle: 5,
            innerRadius: 30,
            outerRadius: 100,
            data: data,
            // cx: 150,
            // cy: 150,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          },
        ]}
        margin={{ top: 100, right: 5 }}
        height={height}
        slotProps={{
          legend: {
            // hidden: true,
            direction: 'row',
            position: {
              horizontal: 'middle',
              vertical: 'bottom',
            },
          },
        }}
      />
    </CardContent>
  );
};
