import { PieChartCard } from '../PieChartCard';

export type OriginProps = {
  local: number;
  international: number;
};

export const OriginReport = (props: { data: OriginProps }) => {
  const { international, local } = props.data;
  const dataset = [
    { label: 'Local', value: local },
    { label: 'International', value: international },
  ];
  return <PieChartCard label='Origin distribution' data={dataset} height={300} />;
};
